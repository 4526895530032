<template>
  <!-- 视频回放 - 视频录制记录 -->
  <div style="margin-top:20px;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="时间：">
        <el-date-picker :default-time="['00:00:00', '23:59:59']" v-model="timeRange" value-format="yyyy-MM-dd HH:mm:ss"
          @blur="onSubmit()" type="datetimerange" range-separator="至" start-placeholder="请选择开始日期"
          end-placeholder="请选择结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="设备：">
        <el-input maxlength="30" v-model="queryForm.ChannelName" @keyup.enter.native="onSubmit()"
          @input="(e) => (queryForm.ChannelName = validSpace(e))" placeholder="请输入设备名称"></el-input>
        <!-- <el-cascader @change="onSubmit()" @clear="onSubmit()" popper-class="my-cascader" :options="options2" :props="props" collapse-tags clearable v-model="MainId"></el-cascader> -->
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <div class="box-card" style="text-align: left;">
      <le-card title="视频录制记录">
        <!-- 主要内容 -->
        <div class="center_body" v-if="tableData.lenght != 0 && !emptyList">
          <div class="catchBox" v-for="(item, i) in tableData" @click.capture="dialogGo(item)" :key="i">
            <el-image style="flex:1;" :src="item.RecordPic" fit="contain"></el-image>
            <div class="catchChannelName">
              <span>{{ item.ChannelName }}</span>
            </div>
            <el-divider></el-divider>
            <div class="catchCreateTime" :title="$moment(item.create_time).format('YYYY-MM-DD HH:mm:ss')" style="justify-content: start;color: #A6A6A6;">
                <div class="innerTop">
                  <span style="color:#01A6FB;">{{ getTimeLength2(item.Duration - 0) }}</span>
                <span style="color: #999999;">{{ validDate(item.StartTime) }}</span>
                </div>
                    <div class="innerTop" style="color: #999999;">
                      <span>{{ validTime(item.StartTime) }}</span> ~
                      <span>{{ validTime(item.EndTime) }}</span>
                    </div>
                </div>
          </div>
          <!-- <div class="rowdiv" v-for="(item, i) in tableData" :key="i">
            <div class="columndiv" v-for="(data, j) in item" :key="j" :id="(i - 1) * rowNumber + (j - 1)">
              <div class="devices_item" v-if="data != null" @click.capture="dialogGo(data)">
                <div class="devices_item_top">
                  <el-image style="flex:1;" :src="data.RecordPic" fit="contain"></el-image>
                  //
                  <video style="flex:1;object-fit: fill" :src="data.DownloadUrl" ref="videoPlayers" width="100%" height="100%"></video>
                  //
                </div>
                <div class="devices_item_bottom">
                  <div class="devices_ib_item">
                    <span>{{ data.ChannelName }}</span>
                    <span style="color: #999999;">{{ validDate(data.StartTime) }}</span>
                  </div>
                  <el-divider></el-divider>
                  <div class="devices_ib_item">
                    <span style="color:#01A6FB;">{{ getTimeLength2(data.Duration - 0) }}</span>
                    <span style="color: #999999;">{{ validTime(data.StartTime) }} ~ {{ validTime(data.EndTime) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
          <!-- 页码 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[30,40,50,60]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        <div v-if="emptyList" class="noMessage"></div>
      </le-card>
      <div>
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" append-to-body :title="title" :visible.sync="dialogStuatus" width="50%">
      <video v-if="videoUrl !== ''" controls :src="videoUrl" ref="videoPlayers" width="100%" height="100%" :id="videoId"
        autoplay style="object-fit: fill"></video>
    </el-dialog>
  </div>
</template>

<script>
import { getRecordFileList, recordPlanSceneDetail } from '@/api/monitoring'
export default {
  data () {
    return {
      // 查询表单
      queryForm: {
        ChannelName: '',
        StartTime: '',
        EndTime: '',
        PageSize: 30,
        PageIndex: 1
        // MainId: [],
      },
      MainId: null,
      timeRange: [],
      total: 0,
      // 表格数据
      tableData: [],
      emptyList: false,
      // 选中的表格数据
      multipleSelection: [],
      dialogStuatus: false,
      recorderDialogList: [],
      rowNumber: 5,
      columNumber: 3,
      title: '',
      videoUrl: '',
      player: null,
      videoId: 'videogo',
      options2: [],
      monitor_info: [],
      props: { multiple: true, value: 'Id', label: 'name' }
    }
  },
  created () {
    this.fnGetTimeCardList()
    // this.requestDevices();
  },
  mounted () {
  },
  destroyed () {
  },
  computed: {

  },
  watch: {
    timeRange (newV, oldV) {
      console.log('=================newV=', newV)
      if (newV) {
        this.queryForm.StartTime = newV[0]
        this.queryForm.EndTime = newV[1]
      } else {
        this.queryForm.StartTime = ''
        this.queryForm.EndTime = ''
        this.onSubmit()
      }
    }
  },
  methods: {
    getTimeLength2 (time) {
      var minute = Math.floor(time / 60)
      var s = time - (minute * 60)
      minute = minute <= 9 ? '0' + minute : minute
      s = s <= 9 ? '0' + s : s
      return minute + ':' + s
    },
    async requestDevices () {
      const res = await recordPlanSceneDetail({ RecordPlanId: -1 })
      if (res.Code === 200) {
        this.options2 = res.Data.scene_info
        this.monitor_info = res.Data.monitor_info
      }
    },
    async fnGetTimeCardList () {
      console.log('this.queryForm===', JSON.stringify(this.queryForm))
      const res = await getRecordFileList(this.queryForm)
      if (res.Data.TotalCount === 0) {
        this.emptyList = true
      }
      // this.tableData = res.Code === 200 && res.Data ? this.listFormdata(res.Data.DataList) : []
      this.tableData = res.Code === 200 && res.Data ? res.Data.DataList : []
      this.total = res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    listFormdata (list) {
      if (list.leghth === 0) return []
      var newList = []
      for (var i = 0; i < this.columNumber; i++) {
        var innerList = []
        for (var j = 0; j < this.rowNumber; j++) {
          const index = j + this.rowNumber * i
          if (index < list.length) {
            innerList.push(list[index])
          } else {
            innerList.push(null)
          }
        }
        newList.push(innerList)
      }
      return newList
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetTimeCardList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetTimeCardList()
    },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetTimeCardList()
      // console.log(`当前页: ${val}`);
    },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    dialogGo (data) {
      this.title = data.ChannelName
      this.videoUrl = data.DownloadUrl
      this.dialogStuatus = true
      var myvideo = document.getElementById(this.videoId)
      myvideo.currentTime = 0
      // myvideo.play();
    }
  }
}
</script>

<style scoped lang="less">
.center_body {
  height: 34vw;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  // flex-direction: column;
  overflow-y: auto;
  .catchBox{
    width: 9%;
    height: 30%;
    padding: 6px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    // margin-right: 0.7%;
    // margin-bottom: 8px;
    margin: 8px 0.5%;
    .catchChannelName{
      padding: 5px;
      line-height: 14px;
      font-size: 14px;
    }
    .catchCreateTime{
      .innerTop{
      display: flex;
      justify-content: space-between;
    }
    }

  }
  .rowdiv {
    height:30%;
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    .columndiv {
      display: flex;
      position: relative;
      flex: 1;
      margin-right: 10px;
      box-sizing: border-box;

      .devices_item {
        background: #ffffff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        flex: 1;
        display: flex;
        flex-direction: column;
        .devices_item_top {
          display: flex;
          flex: 1;
          box-sizing: border-box;
          overflow: hidden;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 8px  8px 0 0;
        }

        .devices_item_bottom {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding:5px 15px;
          .devices_ib_item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            // text-align: center;
          }
        }
      }
    }
  }
}
.noMessage{
  height: 35vw;
  width: 100%;
  background-image:url('~@/assets/img/ychj/nomessage.png');
  background-repeat: no-repeat;
  background-size: 16% 35%;
  background-position: center center;
}
.el-dialog__wrapper {
  /deep/.el-dialog {
    height: 50%;

    .el-dialog__header {
      height: 0 !important;
      padding: 0 !important;

      .el-dialog__headerbtn {
        right: 5px !important;
        top: 5px !important;
        width: 30px !important;
        height: 30px !important;
        background-color: rgba(0, 0, 0, 0.8) !important;
        border-radius: 15px !important;
        overflow: hidden !important;
        z-index: 9999 !important;
      }
    }

    .el-dialog__body {
      height: 100%;
      padding: 0 !important;
    }
  }
}
/deep/.el-divider--horizontal{
  margin: 5px 0 !important;
}
</style>
